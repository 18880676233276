import React, {useRef, useState, useEffect} from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as text from '../styles/text.module.css';
import * as themes from '../styles/themes.module.css';
import {themesToOptions, colorVariants, pickVariantWithTheme, passiveArg} from '../utils';
import Heading, {headingTags} from './Heading';
import * as css from './Hero.module.css';
import Image from './Image';
import {InlayFromEdge} from './Inlay';
import Promotional from './Promotional';

const Hero = ({
  eyebrow,
  eyebrowVariant,
  heading,
  headingSize,
  headingVariant,
  cta,
  image,
  imagePosition,
  children,
  reverse,
  inlay,
  roundImage,
  textWidth,
  imageWidth: baseImageWidth,
  inlayHeight,
  theme = 'blackToDarkgreen',
  className,
}) => {
  const ref = useRef();
  const [imageHeight, setImageHeight] = useState(roundImage ? '80%' : '100%');
  const [imageWidth, setImageWidth] = useState(roundImage ? '80%' : '100%');
  useEffect(() => {
    const measure = e => {
      if (!ref.current) return;
      const ratio = parseFloat(baseImageWidth) / 100;

      // < @media --medium
      if (window.innerWidth < 990) {
        setImageHeight(roundImage ? '80%' : '100%');
        setImageWidth(undefined);
      } else {
        setImageHeight(ref.current.offsetHeight * (roundImage ? 0.8 : 1));
        setImageWidth(ref.current.offsetWidth * (isNaN(ratio) ? 0.5 : ratio));
      }
    };
    window.addEventListener('resize', measure, passiveArg);
    measure();
    () => {
      window.removeEventListener('resize', measure, passiveArg);
    };
  }, []);

  const classes = classnames(css.root, className, css[theme], themes[theme], {
    [css.withImage]: image,
    [css.reverse]: reverse,
    [css.withInlay]: inlay,
    [css.withRound]: roundImage,
  });

  return (
    <div className={classes} ref={ref}>
      <div className={css.text} style={{flexBasis: textWidth}}>
        <div className={css.titleWideViewport}>
          {eyebrow && (
            <Promotional className={css.eyebrow} size="s" variant={eyebrowVariant || pickVariantWithTheme(theme)}>
              {eyebrow}
            </Promotional>
          )}
          <Heading
            size={headingSize}
            variant={headingVariant || pickVariantWithTheme(theme)}
            className={classnames(css.title)}
          >
            {heading}
          </Heading>
        </div>
        <div className={css.divider} />
        <div className={css.content}>{children}</div>
        {cta &&
          React.cloneElement(cta, {
            variant: pickVariantWithTheme(theme),
          })}
      </div>
      {image && (
        <>
          <div className={css.image} style={{flexBasis: baseImageWidth}}>
            <Image
              src={image}
              className={css.imageElement}
              inlay={inlay ? (reverse ? 'left' : 'right') : false}
              roundTopLeft={!reverse && roundImage}
              roundTopRight={reverse && roundImage}
              roundBottomLeft={!reverse && roundImage}
              roundBottomRight={reverse && roundImage}
              imagePosition={imagePosition}
              height={imageHeight}
              imageWidth={imageWidth}
            />
          </div>
          <div className={css.titleNarrowViewport}>
            {eyebrow && (
              <Promotional className={css.eyebrow} size="s" variant={eyebrowVariant || pickVariantWithTheme(theme)}>
                {eyebrow}
              </Promotional>
            )}
            <Heading
              size={headingSize}
              variant={headingVariant || pickVariantWithTheme(theme)}
              className={classnames(text.headingXl, css.title)}
            >
              {heading}
            </Heading>
          </div>
        </>
      )}
      {inlay && !image && (
        <>
          <InlayFromEdge
            style={{flexBasis: imageWidth}}
            height={inlayHeight}
            from={reverse ? 'left' : 'right'}
            className={css.inlay}
            id="hero"
          />
        </>
      )}
    </div>
  );
};

Hero.interactiveProps = {
  theme: {
    type: 'select',
    options: themesToOptions(themes),
  },
  headingSize: {
    type: 'select',
    options: Object.keys(headingTags),
  },
  headingVariant: {
    type: 'select',
    options: colorVariants,
  },

  eyebrowVariant: {
    type: 'select',
    options: colorVariants,
  },

  reverse: {type: 'bool'},
  roundImage: {type: 'bool'},
  inlay: {type: 'bool'},
  imageWidth: {
    type: 'number',
  },
  textWidth: {
    type: 'number',
  },
};

Hero.propTypes = {
  eyebrow: PropTypes.string,
  eyebrowVariant: PropTypes.oneOf(Hero.interactiveProps.eyebrowVariant.options),
  heading: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  headingSize: PropTypes.oneOf(Hero.interactiveProps.headingSize.options),
  headingVariant: PropTypes.oneOf(Hero.interactiveProps.headingVariant.options),
  cta: PropTypes.element,
  image: PropTypes.string,
  imagePosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  reverse: PropTypes.bool,
  inlay: PropTypes.bool,
  roundImage: PropTypes.bool,
  textWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inlayHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

export default Hero;
